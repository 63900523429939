import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from '../layouts';
import BlockContent from "@sanity/block-content-to-react"
import ContentBlocks from '../components/Content/Blocks';
import style from "./index.module.css";

const IndexPage = ({ data }) => {
  const sources = [
    data.page.imageSmall.asset.fluid,
    {
      ...data.page.imageLarge.asset.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <Layout layoutFull={true}>
      <Helmet>
        <title>{data.page.title}</title>
        <meta name="description" content={data.page.metaDescription} />
      </Helmet>
      <div>
        {data.page.imageLarge &&
          <Img className={style.headerImage} fluid={sources} alt="Camper" />
        }
        <div className={style.headerSubtitle}>
          {data.page.subtitle}
        </div>
      </div>
      <div className="panel">
        <div className="home-intro content-text">
          {data.page.intro &&
            <div className="intro">
              {data.page.intro}
            </div>
          }
          {data.page._rawBody &&
            <BlockContent blocks={data.page._rawBody} />
          }
        </div>
      </div>

      <div className="panel panel--xlarge">
        <ContentBlocks blocks={data.page._rawContent} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    page: sanityHomepage {
      title
      metaDescription
      subtitle
      imageSmall {
        asset {
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      imageLarge {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      intro
      _rawBody
      _rawContent(resolveReferences: {maxDepth: 5})
      _rawHomepageItems(resolveReferences: {maxDepth: 10})
    }
  }
`